<template>
  <vue-modal
    :show-spinner="isLoading"
    :show-modal="showModal"
    :modal-extra-large="true"
    :modal-dialog-scrollable="true"
    :primary-footer-button-translation="$t('pages.payroll.download')"
    :loading-primary-footer-button="isLoading"
    @[saveEvent]="onSaveButtonClicked"
    @[closeEvent]="onCloseButtonClicked"
    @[secondaryEvent]="onCloseButtonClicked"
  >
    <template #body>
      <pdf-js-viewer :src="src"></pdf-js-viewer>
    </template>
  </vue-modal>
</template>

<script>
import VueModal, {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";
import PdfJsViewer from "@/components/PdfJsViewer";
import { SHOW_PDF_VIEWER_MODAL_EVENT } from "./index";

import api from "@/api/payroll.js";

export default {
  name: "PdfPayrollModal",
  components: {
    VueModal,
    PdfJsViewer,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      month: 0,
      year: 0,
      src: "",
    };
  },
  created() {
    EventBus.listen(SHOW_PDF_VIEWER_MODAL_EVENT, ({ month, year }) => {
      this.month = month;
      this.year = year;
      this.showModal = true;
    });
  },
  watch: {
    async showModal(val) {
      if (val) {
        this.isLoading = true;

        const { data } = await api.getPdfPayroll({ month: this.month, year: this.year });
        this.src = data.body;

        this.isLoading = false;
      }
    },
  },
  beforeDestroy() {
    EventBus.off(SHOW_PDF_VIEWER_MODAL_EVENT);
  },
  computed: {
    saveEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
  },
  methods: {
    onSaveButtonClicked() {
      this.isLoading = true;
      api.downloadPdfPayroll({ month: this.month, year: this.year }).then((response) => {
        const type = response.headers["content-type"];
        const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Nomina_${this.month}_${this.year}.pdf`;
        link.click();
        this.isLoading = false;
      });
    },
    onCloseButtonClicked() {
      this.clear();
    },
    clear() {
      this.src = "";
      this.showModal = false;
    },
  },
};
</script>

<style scoped></style>
