import { parseISOElseNull } from "@/utils/functions";
export default class Payroll {
  parse(data) {
    this.id = `${data.postedMonth}-${data.postedYear}`;
    this.postedEmployeeCode = data.postedEmployeeCode;
    this.postedPayrollNumber = data.postedPayrollNumber;
    this.postedNumber = data.postedNumber;
    this.postedDate = parseISOElseNull(data.postedDate);
    this.postedSigned = data.postedSigned === "true" || data.postedSigned === true;
    this.postedTotalAmount = data.postedTotalAmount;
    this.postedDescription = data.postedDescription;
    this.postedIban = data.postedIban;
    this.postedMonth = data.postedMonth;
    this.postedYear = data.postedYear;

    return this;
  }
}
