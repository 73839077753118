<template>
  <main role="main" class="container" v-if="activeEmployeePayrolls">
    <article>
      <h1 class="mb-4">{{ $t("pages.payroll.title") }}</h1>

      <div class="card payroll-searching mb-4">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <span class="search-header">{{ $t("pages.payroll.searching_header") }}</span>
              <img src="@/assets/IC_Minimise.png" alt="min" />
            </div>
          </div>
          <form>
            <div class="row mt-3">
              <div class="col-md-2">
                <year-field :form="form" name="year" />
              </div>
              <div class="col-md-10">
                <pay-roll-status-field :form="form" name="signed" />
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <vue-button
                  :title="$t('components.actions.search')"
                  class="btn-primary search-button"
                  @click="init"
                ></vue-button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <vue-data-table
        :table-columns="columns"
        :table-rows="payrolls"
        :is-loading="isLoading"
        :empty-rows-message="$t('components.table.no_data')"
      >
        <template #table-row="props">
          <template v-if="props.cell.getColumn.id === 'postedDate'">
            <formatted-date-component :val="props.cell.getValue" />
          </template>
          <template v-else-if="props.cell.getColumn.id === 'postedSigned'">
            <img src="@/assets/IC_Check_green.png" alt="alert" v-if="props.cell.getValue" />
            <img src="@/assets/IC_Alert_card.png" alt="alert" v-else />
          </template>
        </template>
        <template #table-action-menu="props">
          <a class="dropdown-item payroll-action" href="#" @click="showPdf(props.row.raw)"
            ><img src="@/assets/IC_Detalle.png" alt="pdf" />
            <span>{{ $t("pages.payroll.pdf") }}</span>
          </a>
          <a
            class="dropdown-item payroll-action"
            href="#"
            @click="showSign(props.row.raw)"
            v-if="!props.row.raw.postedSigned"
            ><img src="@/assets/IC_Firmar.png" alt="pdf" />
            <span>{{ $t("pages.payroll.sign") }}</span>
          </a>
        </template>
      </vue-data-table>
    </article>

    <pdf-payroll-modal></pdf-payroll-modal>
    <sign-payroll-modal></sign-payroll-modal>
  </main>
</template>

<script>
import FormattedDateComponent from "@/components/formatted/FormattedDateComponent";
import api from "@/api/payroll";
import VueDataTable, { Column } from "@/components/table";

import PdfPayrollModal from "@/pages/employee/payroll/PdfPayrollModal";
import SignPayrollModal from "@/pages/employee/payroll/SignPayrollModal";
import { VueButton, Form } from "@/components/form";
import { PayRollStatusField, YearField } from "@/pages/components/fields";
import { mapGetters } from "vuex";

import {
  Payroll,
  PAYROLL_SIGNED_EVENT,
  SHOW_PDF_VIEWER_MODAL_EVENT,
  SHOW_SIGN_MODAL_EVENT,
} from "./index";

import { WhoAmIMixin } from "@/components/mixins";

export default {
  mixins: [WhoAmIMixin],
  name: "PayRollPage",
  layout: "MainLayout",
  components: {
    PdfPayrollModal,
    FormattedDateComponent,
    VueDataTable,
    SignPayrollModal,
    VueButton,
    PayRollStatusField,
    YearField,
  },
  middleware: ["auth", "notification"],
  data() {
    return {
      isLoading: false,
      payrolls: [],
      form: new Form({
        data: {
          signed: null,
          year: null,
        },
      }),
      columns: [
        new Column({
          id: "postedSigned",
          name: "pages.payroll.posted_signed",
        }),
        new Column({
          id: "postedDate",
          name: "pages.payroll.posted_date",
        }),
        new Column({
          id: "postedMonth",
          name: "pages.payroll.posted_month",
        }),
        new Column({
          id: "postedYear",
          name: "pages.payroll.posted_year",
        }),
        new Column({
          id: "postedIban",
          name: "pages.payroll.posted_iban",
        }),
        new Column({
          id: "postedDescription",
          name: "pages.payroll.posted_description",
        }),
      ],
    };
  },
  created() {
    this.init();
    EventBus.listen(PAYROLL_SIGNED_EVENT, ({ postedNumber }) => {
      let payroll = this.payrolls.find((x) => x.postedNumber === postedNumber);
      if (this.$isset(payroll)) {
        payroll.postedSigned = true;
      }
    });
  },
  beforeDestroy() {
    EventBus.off(PAYROLL_SIGNED_EVENT);
  },
  computed: {
    ...mapGetters("auth", ["activeEmployeePayrolls"]),
  },
  methods: {
    init() {
      this.isLoading = true;

      var data = this.form.data();
      data.year = data.year ?? new Date().getFullYear();

      api
        .getPayrolls({ ...data })
        .then((response) => {
          let payrolls = [];
          response.data.forEach((element) => {
            payrolls.push(new Payroll().parse(element));
          });

          this.payrolls = payrolls;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    showPdf(row) {
      EventBus.fire(SHOW_PDF_VIEWER_MODAL_EVENT, {
        month: row.postedMonth,
        year: row.postedYear,
      });
    },
    showSign(row) {
      EventBus.fire(SHOW_SIGN_MODAL_EVENT, { postedNumber: row.postedNumber });
    },
  },
};
</script>

<style lang="scss" scoped>
.payroll-action {
  padding-left: 5px;
  color: $color-primary;

  & > img {
    margin-right: 5px;
  }
}

.payroll-searching {
  .search-header {
    color: $color-secondary;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-right: 5px;
  }

  .search-button {
    border-radius: $border-radius;
    height: 50px;
    width: 125px;
  }
}
</style>
