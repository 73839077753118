<template>
  <vue-modal
    :modal-title="$t('pages.payroll.sign_title')"
    :show-spinner="isLoading"
    :show-modal="showModal"
    :primary-footer-button-translation="$t('pages.payroll.sign_send')"
    :secondary-footer-button-translation="$t('pages.payroll.sign_undo')"
    :loading-primary-footer-button="isLoading"
    @[primaryEvent]="onPrimaryButtonClicked"
    @[secondaryEvent]="onSecondaryButtonClicked"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <VueSignaturePad
        v-if="showModal"
        id="signature"
        width="100%"
        height="200px"
        ref="signaturePad"
      />
    </template>
  </vue-modal>
</template>

<script>
import VueModal, {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";
import { SHOW_SIGN_MODAL_EVENT, PAYROLL_SIGNED_EVENT } from "./index";
import api from "@/api/payroll";

export default {
  name: "SignPayrollModal",
  components: {
    VueModal,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      postedNumber: "",
      options: {
        penColor: "#c0f",
      },
    };
  },
  created() {
    EventBus.listen(SHOW_SIGN_MODAL_EVENT, ({ postedNumber }) => {
      this.postedNumber = postedNumber;
      this.showModal = true;
    });
  },
  beforeDestroy() {
    EventBus.off(SHOW_SIGN_MODAL_EVENT);
  },
  computed: {
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
  },
  methods: {
    async onPrimaryButtonClicked() {
      try {
        this.isLoading = true;
        const { isEmpty, data } = await this.$refs.signaturePad.saveSignature();

        if (!isEmpty) {
          await api.sendSign({ sign: data, postedNumber: this.postedNumber });
          EventBus.fire(PAYROLL_SIGNED_EVENT, { postedNumber: this.postedNumber });
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
      this.showModal = false;
    },
    onSecondaryButtonClicked() {
      this.clear();
    },
    onCloseButtonClicked() {
      this.showModal = false;
    },
    clear() {
      this.$refs.signaturePad.undoSignature();
    },
  },
};
</script>

<style scoped></style>
